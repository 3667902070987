import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function Gallery() {
  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 3
  };
  return (
    <Slider {...settings}>
      <div className="gallery__slide">
        <img src={'/gallery/gallery-1.webp'} alt="Gallery Image 1" />
      </div>
      <div className="gallery__slide">
      <img src={'/gallery/gallery-2.webp'} alt="Gallery Image 2" />
      </div>
      <div className="gallery__slide">
      <img src={'/gallery/gallery-3.webp'} alt="Gallery Image 3" />
      </div>
      <div className="gallery__slide">
      <img src={'/gallery/gallery-4.webp'} alt="Gallery Image 4" />
      </div>
      <div className="gallery__slide">
      <img src={'/gallery/gallery-5.webp'} alt="Gallery Image 5" />
      </div>
      <div className="gallery__slide">
      <img src={'/gallery/gallery-6.webp'} alt="Gallery Image 6" />
      </div>
      <div className="gallery__slide">
      <img src={'/gallery/gallery-7.webp'} alt="Gallery Image 7" />
      </div>
      <div className="gallery__slide">
      <img src={'/gallery/gallery-8.webp'} alt="Gallery Image 8" />
      </div>
      <div className="gallery__slide">
      <img src={'/gallery/gallery-9.webp'} alt="Gallery Image 9" />
      </div>
      <div className="gallery__slide">
      <img src={'/gallery/gallery-10.webp'} alt="Gallery Image 10" />
      </div>
      <div className="gallery__slide">
      <img src={'/gallery/gallery-11.webp'} alt="Gallery Image 11" />
      </div>
      <div className="gallery__slide">
      <img src={'/gallery/gallery-12.webp'} alt="Gallery Image 12" />
      </div>
    </Slider>
  );
}

export default Gallery;