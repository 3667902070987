import React from "react"
import HomepageMarquee from "../components/HomepageMarquee"
import AboutDirector from "../components/AboutDirector"
import Gallery from "../components/Gallery"
import Layout from "../components/Layout"

export default function Home() {
  return (
    <Layout>
      <HomepageMarquee />
      <div className="container">
        <div className="section section__aboutus">
          <h2>About Us</h2>
          <p>Welcome to LiaLash Beauty, your one stop shop for your beauty needs making you feel beautiful. 
            We are dedicated to delivering high quality services and products with the goal of making you 
            look and feel your best. </p>
          <p>With over 10 years of experience and staying on top of popular trends, you can have confidence 
            in having your beauty treatments with us.</p>
          </div>
        <div className="section section__booking">
          <h2>Book Now</h2>
          <iframe src="https://bookings.gettimely.com/lialashbeauty/bb/book" title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe>
        </div>
        <div className="section section__gallery">
          <h2>Gallery</h2>
          <Gallery />
        </div>
        <AboutDirector />
        {/* <div className="section section__instagram">
          <h2>Visit our instagram</h2>
        </div> */}
      </div>
    </Layout>
  )
}
