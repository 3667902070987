import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slideImage from "../../images/new/hero-image2.jpg";
import slideImageTwo from "../../images/new/LLB-background-two.jpg";


function HomepageMarquee() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    className: 'homepage__slider',
    slidesToScroll: 1
  };
  return (
    <Slider {...settings}>
      <div className="marquee__slide">
        <div style={{ backgroundImage: `url(${slideImage})` }}>
            <div className="slide__content">
                <h2>Welcome to LiaLash Beauty</h2>
                <a className="button" href="/bookappointment">Book Now</a>
            </div>
        </div>
      </div>
      <div className="marquee__slide">
        <div style={{ backgroundImage: `url(${slideImageTwo})` }}>
            <div className="slide__content">
                <h2>Join the Academy</h2>
                <a className="button" href="/academy">Learn more</a>
            </div>
        </div>
      </div>
    </Slider>
  );
}

export default HomepageMarquee;